import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/components/src/lib/Layout/Layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider","default","useAuth"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/hooks/src/lib/useAuth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Air.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/AllProducts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/AllRequests.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Copy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Delete.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/ExportRequest.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/FreeSales.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/LiveAnimal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/OrganicCert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Sea.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src949119477/src/packages/ui-dfe/icons/src/lib/Wine.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PermissionProvider"] */ "/codebuild/output/src949119477/src/packages/ui-permission/src/lib/hooks/PermissionProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src949119477/src/packages/ui-typesense-client/src/lib/TypesenseProvider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src949119477/src/packages/ui-typesense-client/src/lib/useTypesenseSearch.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src949119477/src/packages/ui-typesense-client/src/lib/useUiTypesenseClient.ts");
